import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

function PhoneMaskInput(props) {
  const { inputRef, mask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={mask}
      placeholderChar={'\u2000'}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      style={{ textAlign: 'center' }}
    />
  );
}

PhoneMaskInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  mask: PropTypes.array.isRequired,
};

export default PhoneMaskInput;
